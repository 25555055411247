import tumGlobal from "../global";
import { addEventDelegate, dataStorage, setValue } from "./dom";
import { loadLib } from "./loadlib";

/**
 * Регистрирует события, которые работают глобально, и нужный для них код загружается по требованию, а не сразу
 * Если импортируется модуль, в котором есть функция init(), ее нужно запускать в самом модуле
 * Импортируемый модуль должен отвечать за какой-то один функционал, не стоит здесь импортировать целый скрипт страницы фирмы или вроде того
 */
function initLazyModules() {
    addEventDelegate(document, 'click', '.toggleSearchMenu', () => {
        import("../searchMenu").then(({ tum_searchMenu }) => tum_searchMenu.toggleMenu());
    });

    addEventDelegate(document, 'click', '.home-catalog-link', (element, e) => {
        if (!tumGlobal.isMob()) {
            location.href = "/catalog";
            return;
        };

        e.preventDefault();
        import("../searchMenu").then(({ tum_searchMenu }) => {
            tum_searchMenu.toggleMenu().then(() => tum_searchMenu.scrollToCatalog());
        });
    });

    addEventDelegate(document, 'click', '.mod-ord-window', (element, event) => {
        event.preventDefault();
        var idFirm = element.getAttribute('data-firmid');
        var idProd = element.getAttribute('data-productid');
        var params = { idFirm: idFirm, idProd: idProd };

        import("../showCaseManagement").then(({ management }) => management.getModalData(params));
    });

    addEventDelegate(document, 'click', '.toggleMenu', () => {
        import("../MainMenu").then(({ tum_mainMenu }) => tum_mainMenu.toggleMenu());
    });

    addEventDelegate(document, 'click', '.editTopListDate', (element) => {
        import("../product/editTopListDate.js").then(({ editTopListDate }) => {
            let productId = element.getAttribute('data-itemid');
            let previewId = element.getAttribute('data-previewid');
            editTopListDate.editTopListDate(productId, previewId);
        });
    });

    addEventDelegate(document, 'click', '.tum-miniatureCartBtn', (element, event) => {
        event.preventDefault();
        import("../cart/cartWidget").then(({ cartWidget }) => cartWidget.showCartWidget(element));
    });

    addEventDelegate(document, 'click', ['.topbar-openCatalog', '.tu-showCatalog'], () => {
        import("../catalogItems").then(({ catItems }) => catItems.showCatalog());
    });

    addEventDelegate(document, 'click', '.firm-edit-item', (element, event) => {
        event.preventDefault();
        import("../firm/firmStatusEdit").then(({ firmStt }) => firmStt.editFirmStatus(event, element));
    });

    addEventDelegate(document, 'click', '.task-update', (element) => {
        import("../tasks.js").then(({ tu_tasks }) => tu_tasks.updateButtonHandler(element));
    });

    addEventDelegate(document, 'click', '[data-edit-preview-connections]', (element) => {
        let productId = element.getAttribute('data-productid');
        let firmId = element.getAttribute('data-firmid');
        let ctuId = element.getAttribute('data-ctuid');

        import("../previews/connection_editor").then(({previewConnectionEditor}) => {
            previewConnectionEditor.open({ productId, ctuId, firmId });
        });
    });

    refreshLazyModules();
}

function refreshLazyModules(){
    for (let crud of document.querySelectorAll('[data-crud]')) {
        import("../crud.js").then(({ initCrud }) => initCrud(crud));
    }

    if (document.querySelector('.tu-modal-form')) {
        import("../form/form.modals").then(({ initFormModals }) => initFormModals());
    }

    if (document.querySelector('[data-tu-select]')) {
        import("../global/tu_select").then(({ initSelects }) => initSelects());
    }

    for (let timer of document.querySelectorAll('[data-timer]')) {
        import("../global/timer").then(({ initTimer }) => initTimer(timer));
    }

    if (document.querySelector('[data-submit-form]')) {
        import("../global/form").then(({ initFormUtils }) => initFormUtils());
    }

    for (let item of document.querySelectorAll('[data-highlight-search]')) {
        import("../global/highlight_search").then(({ initHighlightSearch }) => {
            initHighlightSearch(item);
        });
    }

    for (let item of document.querySelectorAll('[data-slider-auto-init]')) {
        import("../slider").then(module => {
            let tu_slider = module.default;
            tu_slider.create({
                container: item,
                mobLoadMoreChevronDirection: 'right',
                autoCycling: true
            });
        });
    }

    for (let item of document.querySelectorAll('[data-range-slider]:not(.noUi-target)')) {
        if (dataStorage.get(item, 'nouislider') || dataStorage.get(item, 'nouislider-initializing')) {
            continue;
        }

        dataStorage.put(item, 'nouislider-initializing', true);

        let from = document.querySelectorAll(item.dataset.fromTarget);
        let to = document.querySelectorAll(item.dataset.toTarget);
        let multiplier = Number(item.dataset.multiplier) || 1;

        import('nouislider/dist/nouislider.css')
        .then(() => loadLib({ cssLink: "/template/nouislider-custom" }))
        .then(() => import("nouislider"))
        .then(module => {
            let nouislider = module.default;
            let slider = nouislider.create(item, {
                connect: true,
                start: [from[0].value, to[0].value],
                range: {
                    min: Math.floor(Number(item.dataset.min) * multiplier),
                    max: Math.ceil(Number(item.dataset.max) * multiplier)
                }
            });

            slider.on('change', () => {
                var value = slider.get();
                setValue(from, Number(value[0]) / multiplier);
                setValue(to, Number(value[1]) / multiplier);
            });

            slider.on('set', () => {
                for (let item of from) {
                    item.dispatchEvent(new Event('change'));
                }
                
                for (let item of to) {
                    item.dispatchEvent(new Event('change'));
                }
            });
            
            dataStorage.put(item, 'nouislider', slider);
            dataStorage.remove(item, 'nouislider-initializing');
        });
    }
}

export { initLazyModules, refreshLazyModules };
